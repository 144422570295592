<template>
  <div>
    <v-overlay :value="overlay">
     <p>Logging you in securely...</p>
    </v-overlay>
  </div>
</template>

<script>

  import mgr from "../../security/security.js";

    export default {
    created: async function ()
    {
        try
        {
          console.log("Attempting something in the callback page");
          //var mgr = this.$store.state.securityModule.mgr;
                var result = await mgr.signinRedirectCallback();
                var returnToUrl = '/';
          if (result.state !== undefined)
          {
            returnToUrl = result.state;
          }
          var user = await mgr.getUser();
          this.$store.commit('securityModule/setIsAuthenticated', true);
          this.$store.commit('securityModule/setUser', user);
                    await this.$store.dispatch('securityModule/getDashboardProfile');

          await this.$store.dispatch('securityModule/getClientsForUser');

                this.$router.push({ path: returnToUrl });
        } catch (e)
        {
          this.$store.commit('securityModule/setIsAuthenticated', false);
          console.log("in the error");
                this.$router.push({ name: 'Unauthorized' });
            }
        }
    }
</script>
