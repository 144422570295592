<template>
  <v-container class="mt-12 n-sub-container"
               style="border:2px solid;float:left;">
    <v-card flat class="ma-6" v-if="!showSuccess">
      <form-field :field="passwordField"
                  :validations="$v.newPassword"
                  v-on:fieldChange="updatePassword"></form-field>

      <v-btn :disabled="$v.newPassword.$invalid"
             color="blue-grey"
             dark
             v-on:click="submit">Submit</v-btn>
    </v-card>
    <div v-if="showSuccess">
      <p>Success. You can now log in with your new password.</p>
    </div>
  </v-container>
</template>
<script>
import FormField from "../objs/Shared/FormField.ts";
import { FieldTypes } from "../objs/Shared/FieldTypes.js";
const validPassword = value => {
  //ensures that input for password contains
  //one lowercase character
  //one uppercase character
  //one digit
  //one special character
  //at least 4 unique characters (this requirement will be met by extension of meeting the previous 4 requirements)
  //between 8 and 25 characters in length
  if (value === "") {
    return true;
  }
  return new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,25}$"
  ).test(value);
};

export default {
  data: function() {
    return {
      newPassword: "",
      showSuccess: false
    };
  },
  computed: {
    
    passwordField: function() {
      return new FormField({
        tier: -1,
        label: "Enter your new password",
        componentName: FieldTypes.TEXT_FIELD,
        value: this.newPassword
      });
    }
  },
  methods: {
    updatePassword: function(event) {
      this.newPassword = event;
    },
    submit: async function() {
      var token = this.$route.query.token;
      var userId = this.$route.query.userId;

      var succeeded = await this.$store.dispatch("securityModule/resetPasswordFromLink", {
        id: userId,
        resetToken: token,
        password: this.newPassword
      });
      if (succeeded)
      {
        this.showSuccess = true;
      }
    }
  },

  validations: {
    newPassword: {
      validPassword
    }
  }
};
</script>
