export default class DashboardUserProfile {
  id?: string | null;
  messagingUserId?: number | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  legacySystemId?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  messagingAddress?: string | null;
  displayName?: string | null;
  preferences: string | null;
  secondaryAlertEmailAddress?: string | null;
  numUnreadMessages?: number | null;

  public constructor(
    params: DashboardUserProfile = {} as DashboardUserProfile
  ) {
    let {
      id = "",
      messagingUserId = null,
      userName = "",
      firstName = "",
      lastName = "",
      title = "",
      legacySystemId = "",
      email = "",
      phoneNumber = "",
      messagingAddress = "",
      displayName = "",
      preferences = "",
      secondaryAlertEmailAddress = "",
      numUnreadMessages = 0
    } = params;
    this.id = id;
    this.messagingUserId = messagingUserId;
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.title = title;
    this.legacySystemId = legacySystemId;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.messagingAddress = messagingAddress;
    this.displayName = displayName;
    this.preferences = preferences;
    this.secondaryAlertEmailAddress = secondaryAlertEmailAddress;
    this.numUnreadMessages = numUnreadMessages;
  }
}
