import mgr from "../../security/security.js";
import Application from "../../objs/Application.ts";
import * as api from "../Services/api.js";
import DashboardUserProfile from "../../objs/DashboardUserProfile.ts";


const copyStateObjects = function copy(source, destination)
{
  var keys = Object.keys(source);
  var i;
  for (i = 0; i < keys.length; i++)
  {
    var key = keys[i];
    var val = source[key];
    if (Array.isArray(val))
    {
      destination[key] = [...val]
    }
    else if (val == null)
    {
      destination[key] = null;
    }
    else if (typeof (val) === 'object' && val != null)
    {
      if (destination[key] == null)
      {
        destination[key] = val;
      } else
      {
        copy(val, destination[key])
      }
    } else
    {
      destination[key] = source[key];
    }
  }
}

export const state = {
  isAuthenticated: false,
  user: '',
  requiresAuth: false,
  userApplications: [],
  dashboardUser: new DashboardUserProfile(),
  backupUser: new DashboardUserProfile()
};

export const mutations = {
  setDashboardUser(state, payload)
  {
    state.dashboardUser = payload;
  },

  backupUser(state)
  {
    copyStateObjects(state.dashboardUser, state.backupUser);
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setRequiresAuth(state, payload) {
    state.requiresAuth = payload;
  },
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
  setUserApplications(state, payload)
  {
    state.userApplications = payload;

    //Set the alerts for the messaging app
    var i;
    for (i = 0; i < state.userApplications.length; i++)
    {
      if (state.userApplications[i].name == 'Secure Messaging')
      {
        state.userApplications[i].alerts = state.dashboardUser.numUnreadMessages;
        break;
      }
    }
  }
};

export const getters = {

};
export const actions = {
  async forgotMyPassword({ commit, state, rootState }, data)
  {
    var msg = "";
    var updated = await api.forgotMyPassword(data);

    //msg = "If there is an account associated with the email address provided, a password recovery link has been sent.";

    //commit("uxModule/setSnackbarMsg", msg, {
    //  root: true
    //});
    //commit("uxModule/setShowSnackbar", true, { root: true });
  },
  async forgotMyUserName({ commit, state, rootState }, data)
  {
    var msg = "";
    var updated = await api.forgotMyUserName(data);

    //msg = "If there is an account associated with the email address provided, a password recovery link has been sent.";

    //commit("uxModule/setSnackbarMsg", msg, {
    //  root: true
    //});
    //commit("uxModule/setShowSnackbar", true, { root: true });
  },
    async resetPasswordFromLink({ commit, state }, payload)
    {

      var msg = "";
      var updated = await api.resetPasswordFromLink(payload);
      if (updated.success)
      {
        msg = "Password reset successfully.";
      } else
      {
        msg = "There was an error resetting this password.";
      }
      commit("uxModule/setSnackbarMsg", msg, {
        root: true
      });
      commit("uxModule/setShowSnackbar", true, { root: true });
      return updated.success;
    },

    async sendPasswordResetLink({ commit, state })
    {
      var msg = "";
      var updated = await api.sendPasswordResetEmailFromDashboard({ userId: state.dashboardUser.id, email: state.dashboardUser.email });
      if (updated.success)
      {
        msg = "Please check your personal email for a password reset link.";
      } else
      {
        msg = "There was an error resetting this password.";
      }
      commit("uxModule/setSnackbarMsg", msg, {
        root: true
      });
      commit("uxModule/setShowSnackbar", true, { root: true });
    },

    async getDashboardProfile({ commit, state })
    {
      console.log("Getting dashboard profile action");
      commit("uxModule/setShowLoader", true, {
        root: true
      });
      var omniId = state.user.profile.sub;
      var sysUserResult = await api.getUser(omniId);
      var sysUser = sysUserResult.data;
      var messagingUserResult = await api.getMessagingUser(omniId);
      var messagingUser = messagingUserResult.data;
      state.dashboardUser.id = omniId;
      state.dashboardUser.userName = sysUser.userName;
      state.dashboardUser.firstName = sysUser.firstName;
      state.dashboardUser.lastName = sysUser.lastName;
      state.dashboardUser.phoneNumber = messagingUser.mobileNumber;
      state.dashboardUser.email = sysUser.email;
      state.dashboardUser.title = sysUser.roles[0];
      state.dashboardUser.legacySystemId = sysUser.legacySystemId;
      state.dashboardUser.messagingUserId = messagingUser.messagingUserId;
      state.dashboardUser.messagingAddress = messagingUser.messagingAddress;
      state.dashboardUser.displayName = messagingUser.displayName;
      state.dashboardUser.preferences = messagingUser.notificationPreference;
      state.dashboardUser.secondaryAlertEmailAddress = messagingUser.secondaryAlertEmailAddress;
      state.dashboardUser.numUnreadMessages = messagingUser.numUnreadMessages;
      commit("uxModule/setShowLoader", false, {
        root: true
      });
    },

    async updateUserProfile({ commit, state })
    {
      commit("uxModule/setShowLoader", true, {
        root: true
      });
      var success = true;
      var userName = state.user.profile.preferred_username;

      //Check first name
      if (state.dashboardUser.firstName != state.backupUser.firstName)
      {
        var response = await api.updateUserFirstName({ userName: userName, firstName: state.backupUser.firstName });
        if (!response.success)
        {
          success = false;
        }
      }

      //Check last name
      if (state.dashboardUser.lastName != state.backupUser.lastName)
      {
        var response = await api.updateUserLastName({ userName: userName, lastName: state.backupUser.lastName });
        if (!response.success)
        {
          success = false;
        }
      }

      //Check email
      if (state.dashboardUser.email != state.backupUser.email)
      {
        var response = await api.updateUserEmail({ userName: userName, email: state.backupUser.email });
        var responseB = await api.updateMessagingUserEmail({ messagingUserId: state.dashboardUser.messagingUserId, email: state.backupUser.email });
        if (!response.success || !responseB.success)
        {
          success = false;
        }
      }


      //Check alert preferences
      if (state.dashboardUser.preferences != state.backupUser.preferences)
      {
        var responseB = await api.updateMessagingUserNotificationPreference({ messagingUserId: state.dashboardUser.messagingUserId, preference: state.backupUser.preferences });
        if (!responseB.success)
        {
          success = false;
        }

      }

      //Check phone number
      if (state.dashboardUser.phoneNumber != state.backupUser.phoneNumber)
      {
        var newPhone = state.backupUser.phoneNumber.replace(/\D/g, '');
        var response = await api.updateUserPhoneNumber({ userName: userName, phoneNumber: newPhone });
        var responseB = await api.updateMessagingUserPhoneNumber({ messagingUserId: state.dashboardUser.messagingUserId, phoneNumber: newPhone });
        if (!response.success || !responseB.success)
        {
          success = false;
        }

      }

      //Check display name
      if (state.dashboardUser.displayName != state.backupUser.displayName)
      {
        var responseB = await api.updateMessagingUserDisplayName({ messagingUserId: state.dashboardUser.messagingUserId, displayName: state.backupUser.displayName });
        if (!responseB.success)
        {
          success = false;
        }
      }

      //Check secondary email alert
      if (state.dashboardUser.secondaryAlertEmailAddress != state.backupUser.secondaryAlertEmailAddress)
      {
        var responseB = await api.updateMessagingUserSecondaryEmail({ messagingUserId: state.dashboardUser.messagingUserId, email: state.backupUser.secondaryAlertEmailAddress });
        if (!responseB.success)
        {
          success = false;
        }
      }

      //Now reset the dashboard user profile
      var omniId = state.user.profile.sub;
      var sysUserResult = await api.getUser(omniId);
      var sysUser = sysUserResult.data;
      var messagingUserResult = await api.getMessagingUser(omniId);
      var messagingUser = messagingUserResult.data;
      state.dashboardUser.id = omniId;
      state.dashboardUser.userName = sysUser.userName;
      console.log(sysUser.firstName);
      state.dashboardUser.firstName = sysUser.firstName;
      state.dashboardUser.lastName = sysUser.lastName;
      state.dashboardUser.phoneNumber = messagingUser.mobileNumber;
      state.dashboardUser.email = sysUser.email;
      state.dashboardUser.title = sysUser.roles[0];
      state.dashboardUser.legacySystemId = sysUser.legacySystemId;
      state.dashboardUser.messagingUserId = messagingUser.messagingUserId;
      state.dashboardUser.messagingAddress = messagingUser.messagingAddress;
      state.dashboardUser.displayName = messagingUser.displayName;
      state.dashboardUser.preferences = messagingUser.notificationPreference;
      state.dashboardUser.secondaryAlertEmailAddress = messagingUser.secondaryAlertEmailAddress;
      state.dashboardUser.numUnreadMessages = messagingUser.numUnreadMessages;

      commit("uxModule/setShowLoader", false, {
        root: true
      });

      var msg = '';
      if (success)
      {
        msg = "Profile successfully updated.";
      } else
      {
        msg = "Error updating profile."
      }

      commit("uxModule/setSnackbarMsg", msg, {
        root: true
      });
      commit("uxModule/setShowSnackbar", true, { root: true });
    },

    async getMessagingUserByOmniId({ commit, state })
    {
      return api
        .getMessagingUserByOmniId(state.user.id)
        .then(response =>
        {
          if (response.data.messagingUserId == null)
          {
            commit("uxModule/setShowSnackbar", false, { root: true });
            var msg = "Please proceed to set up your messaging profile";
            commit("uxModule/setSnackbarMsg", msg, {
              root: true
            });
            commit("uxModule/setShowSnackbar", true, { root: true });
          } else
          {
            commit("setDashboardUser", response.data, {
              root: true
            });
          }
        });
    },

    async confirmAccount({ commit, state }, payload)
    {
      var response = await api.confirmAccount(payload);
      return response.success;
  },

  async requestActivationLink({ commit, state }, payload)
  {
    var response = await api.requestActivationLink(payload);
    return response.success;
  },

  async getClientsForUser({ commit, state })
  {
    var apps = await api.getClientsForUser(state.user.profile.preferred_username);
    console.log(apps);
    if (apps.success)
    {
      commit('setUserApplications', apps.data);
    }
  },

  async shallowAuthenticate({ commit, state, dispatch })
  {
    await mgr.signinSilent().then(user =>
    {
    }).catch(e =>
    {
    });
 
    let user = await mgr.getUser();
    if (!!user)
    {
      state.user = user;
      //load the dashboard user
      if (state.dashboardUser.id == "")
      {
        await dispatch('getDashboardProfile')
      }

      if (state.userApplications.length == 0)
      {
        var apps = await api.getClientsForUser(state.user.profile.preferred_username);
        if (apps.success)
        {
          commit('setUserApplications', apps.data);
        }

      }


      state.isAuthenticated = true;

     
    }
  },
    async authenticate({ commit, state, dispatch }, returnPath)
    {
      commit("uxModule/setShowLoader", true, {
        root: true
      });
    let user = await mgr.getUser();
    if (!!user) {
      state.user = user;
      //load the dashboard user
      if (state.dashboardUser.id == "")
      {
        await dispatch('getDashboardProfile')
      }
      if (state.userApplications.length == 0)
      {
        var apps = await api.getClientsForUser(state.user.profile.preferred_username);
        if (apps.success)
        {
          commit('setUserApplications', apps.data);
        }

      }


      state.isAuthenticated = true;
      commit("uxModule/setShowLoader", false, {
        root: true
      });
    } else {
      var signedIn = await returnPath
        ? mgr.signinRedirect({ state: returnPath })
        : mgr.signinRedirect();
      commit("uxModule/setShowLoader", false, {
        root: true
      });
    }
  },
  async logout({ commit, state }) {
    state.isAuthenticated = false;
    await mgr.signoutRedirect();
  },
 
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
