import Welcome from "../components/welcome";
import Main from "../components/main"
import Callback from "../components/Security/callback";
import SiteGridContainer from "../components/ApplicationTileGrid/grid-container";
import NewPassword from "../components/new-password";
import Confirm from "../components/confirm";
import UserProfile from "../components/user-profile-display";
import ForgotPassword from "../components/forgot-password"
import ForgotUsername from "../components/forgot-username"
import Store from '../store/index';


export const routes = [

  {
    name: "Home",
    path: "/",
    icon: "pen",
    title: "Home",
    component: Main,
    onNavBar: false,
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false
  },
  {
    name: "SiteContainer",
    path: "/apps",
    icon: "tablet-alt",
    title: "Apps",
    component: SiteGridContainer,
    onNavBar: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ResetPassword",
    path: "/reset",
    icon: "pen",
    title: "NewPassword",
    component: NewPassword,
    onNavBar: false
  },
  {
    name: "ForgotPassword",
    path: "/forgot-password",
    icon: "pen",
    title: "ForgotPassword",
    component: ForgotPassword,
    onNavBar: false
  },
  {
    name: "ForgotUsername",
    path: "/forgot-username",
    icon: "pen",
    title: "ForgotUsername",
    component: ForgotUsername,
    onNavBar: false
  },
  {
    name: "Confirm",
    path: "/confirm",
    icon: "pen",
    title: "Confirm",
    component: Confirm,
    onNavBar: false
  },
  {
    name: "UserProfile",
    path: "/userProfile",
    icon: "user-circle",
    title: "Profile",
    component: UserProfile,
    onNavBar: true,
    meta: {
      requiresAuth: true
    }
  }
];
