import axios from "axios";
var urlPrefix = "https://api.omniuserportal.com";
var securityUrlPrefix = "https://api.omnisecurityportal.com";

export function getUser(data) {
  return axios
    .get(securityUrlPrefix + "/api/identity/getUser", {
      params: {
        userId: data
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { success: false, data: error };
    });
}

export function getMessagingUser(data) {
  return axios
    .get(urlPrefix + "/api/messaging/getMessagingUserByOmniUserId", {
      params: {
        userId: data
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { success: false, data: error };
    });
}

export function forgotMyPassword(data) {
  return axios
    .post(securityUrlPrefix + "/api/identity/forgotMyPassword", null, {
      params: {
        emailAddress: data
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { success: false, data: error };
    });
}

export function forgotMyUserName(data) {
  return axios
    .post(securityUrlPrefix + "/api/identity/forgotMyUserName", null, {
      params: {
        emailAddress: data
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { success: false, data: error };
    });
}

export async function updateMessagingUserEmail(payload) {
  return axios
    .post(urlPrefix + "/api/messaging/UpdateMessagingUserEmail", null, {
      params: {
        messagingUserId: payload.messagingUserId,
        email: payload.email
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateMessagingUserSecondaryEmail(payload) {
  return axios
    .post(
      urlPrefix + "/api/messaging/UpdateMessagingUserSecondaryEmail",
      null,
      {
        params: {
          messagingUserId: payload.messagingUserId,
          email: payload.email
        }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateMessagingUserPhoneNumber(payload) {
  return axios
    .post(urlPrefix + "/api/messaging/UpdateMessagingUserPhoneNumber", null, {
      params: {
        messagingUserId: payload.messagingUserId,
        phoneNumber: payload.phoneNumber
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateMessagingUserDisplayName(payload) {
  return axios
    .post(urlPrefix + "/api/messaging/UpdateMessagingUserDisplayName", null, {
      params: {
        messagingUserId: payload.messagingUserId,
        displayName: payload.displayName
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateMessagingUserNotificationPreference(payload) {
  console.log("Update messaging preference");
  return axios
    .post(
      urlPrefix + "/api/messaging/UpdateMessagingUserNotificationPreference",
      null,
      {
        params: {
          messagingUserId: payload.messagingUserId,
          preference: payload.preference
        }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateUserFirstName(payload) {
  console.log("In update first name, payload is: " + payload);
  return axios
    .post(securityUrlPrefix + "/api/identity/updateUserFirstName", null, {
      params: {
        userName: payload.userName,
        firstName: payload.firstName
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateUserLastName(payload) {
  return axios
    .post(securityUrlPrefix + "/api/identity/updateUserLastName", null, {
      params: {
        userName: payload.userName,
        lastName: payload.lastName
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function confirmAccount(payload) {
  return axios
    .post(securityUrlPrefix + "/api/identity/confirmAccount", null, {
      params: {
        userId: payload.userId,
        token: payload.token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function requestActivationLink(payload) {
  return axios
    .post(securityUrlPrefix + "/api/identity/requestWelcomeEmail", null, {
      params: {
        userId: payload.userId
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateUserEmail(payload) {
  return axios
    .post(securityUrlPrefix + "/api/identity/updateUserEmail", null, {
      params: {
        userName: payload.userName,
        email: payload.email
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateUserPhoneNumber(payload) {
  return axios
    .post(securityUrlPrefix + "/api/identity/updateUserPhoneNumber", null, {
      params: {
        userName: payload.userName,
        phoneNumber: payload.phoneNumber
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateDashboardUser(payload) {
  return axios
    .post(urlPrefix + "/api/updateDashboardUser", payload)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function resetPassword() {
  return axios
    .post(urlPrefix + "/api/resetPassword", { params: {} })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export function getClientsForUser(data) {
  return axios
    .get(securityUrlPrefix + "/api/identity/getClientsForUser", {
      params: {
        userName: data
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { success: false, data: error };
    });
}

//SEND PASSWORD RESET EMAIL
export function resetPasswordFromLink(data) {
  return axios
    .post(securityUrlPrefix + "/api/identity/resetPasswordWithToken", data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { success: false, data: error };
    });
}

export async function sendPasswordResetEmailFromDashboard(payload) {
  return axios
    .post(
      securityUrlPrefix + "/api/identity/sendPasswordResetEmailFromDashboard",
      null,
      {
        params: {
          userId: payload.userId,
          email: payload.email
        }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function sendFeedbackEmail(data) {
  return axios
    .post(urlPrefix + "/api/shared/sendFeedbackEmail", data)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}
