<template>
  <div>
    <v-app-bar dark app clipped-left color="#1c344b">
      <app-bar-nav-icon show="true" />

      <v-toolbar-title class="omni-title mt-1">OMNI USER PORTAL</v-toolbar-title>

      <v-spacer></v-spacer>
      <p v-show="isAuthenticated" class="nav-user-text mr-10 mt-4">Hello, {{username}}</p>
      <speeddial class="mt-8"></speeddial>

    </v-app-bar>
  </div>
</template>

<script>
import AppBarNavIcon from "./app-bar-nav-icon.vue";
  import Speeddial from "./speeddial"
export default {
  components: {
    "app-bar-nav-icon": AppBarNavIcon,
    "speeddial": Speeddial,
  },
    computed: {
     isAuthenticated: function ()
      {
        return this.$store.state.securityModule.isAuthenticated;
      },
      username: function ()
      {
        if (this.isAuthenticated)
        {
          return this.$store.state.securityModule.user.profile.preferred_username;

        } else
        {
          return '';
        }
      },
    }
};
</script>
