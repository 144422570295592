<template>
  <div>
  <div v-show="!submitted">
    <v-row>
      <v-col cols="10">
        <p style="text-align:justify;"><span style="font-weight:bold; color: #1c344b;">Forgot your password?</span> <br />Enter your email. If there is an account associated with that email, we'll send you a password recovery link.</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="5">
        <form-field :field="emailField"
                    :validations="$v.values.email"
                    v-on:fieldChange="updateEmailAddress"></form-field>
      </v-col>
    </v-row>

    <v-row>
      <v-btn color="blue-grey"
             dark v-on:click="sendPasswordResetLink">Recover my password</v-btn>
    </v-row>
    </div>

    <div v-show="submitted" style="margin-top:30px;">
      <p style="text-align:center; font-weight: bold; color:#1c344b;">Thank you.</p>
      <p style="text-align:center;">If there is an account associated with the email address provided, <br />a password recovery link has been sent.</p>
      </div>
  </div>
</template>

<script>
  import FormField from "../objs/Shared/FormField.ts";
  import { FieldTypes } from "../objs/Shared/FieldTypes.js";
    import {required,email} from "vuelidate/lib/validators";


  export default {
    data: function ()
    {
      return {
        emailAddress: '',
        submitted: false
      }
    },
    computed: {
       emailField: function() {
      return new FormField({
        tier: -1,
        label: "Your email address",
        componentName: FieldTypes.TEXT_FIELD,
        value: this.emailAddress,
        validationsLabel: "Email address"
      });
      },
            values: function () {
              var email = this.emailAddress;
        
        var vals = {
          email: email
        };
        return vals;
      },
    },
    methods: {
      updateEmailAddress(event)
      {
        this.emailAddress = event;
      },
      sendPasswordResetLink: async function ()
      {
        this.$v.values.$touch();
        var valid = !this.$v.$invalid;
        if (valid)
        {
          await this.$store.dispatch('securityModule/forgotMyPassword', this.emailAddress);
          this.submitted = true;
        }
      }
    },
     validations() {
      let vals = {
       
        email: {
          email: email,
          required: required,
        }
      }
      return { values: vals }
    },
  }
</script>
