<template>
  <div>
    <div class="v-list-item__action v-list-item__action--stack">
      <user-profile-modal></user-profile-modal>
      <span class="v-list-item__action-text">Edit profile</span>
    </div>

    <div class="v-list-item__action v-list-item__action--stack">
      <v-btn
        @click="passwordReset"
        dark
        icon
        color="blue-grey lighten-1"
        class="mr-6"
        ><icon icon="unlock-alt"></icon> </v-btn
      ><span class="v-list-item__action-text">Reset Password</span>
    </div>
    <!-- <div
      class="v-list-item__action v-list-item__action--stack"
      v-if="messagingUserEmpty"
    >
      <v-btn
        @click="passwordReset"
        dark
        icon
        color="blue-grey lighten-1"
        class="mr-12"
        ><icon icon="plus"></icon>
      </v-btn>
      <span class="v-list-item__action-text">Create messaging profile</span>
    </div> -->
  </div>
</template>
<script>
import UserProfileModal from "./user-profile-modal";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    "user-profile-modal": UserProfileModal
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("securityModule", {
      user: state => state.dashboardUser
    })
    // messagingUserEmpty: function() {
    //   return this.user.messagingUserId == null;
    // }
  },
  methods: {
    ...mapActions("securityModule", ["sendPasswordResetLink"]),
    passwordReset: function() {
      return this.sendPasswordResetLink();
    }
  }
};
</script>
