<template>
  <v-container v-if="!fromCallback" class="mt-12 n-sub-container"
               style="border:2px solid;float:left;">
    <v-card flat class="mar-6" v-if="!showSuccess && ! showFailure &&!shoeEmailSuccess &&!showEmailFailure">
      <p v-if="!fromCallback">Please confirm your account to complete registration.</p>

      <v-btn v-if="!fromCallback" color="blue-grey"
             dark
             v-on:click="submit">Confirm</v-btn>
    </v-card>
    <p v-if="showSuccess">
      Thank you. Your account is now activated. Please login to complete your profile.
    </p>
    <p v-show="showFailure">
      Your activation link has expired. Request a new activation link, and follow the instructions in the email to activate your account and log in.
    </p>
    <v-btn v-show="showFailure" color="blue-grey"
           dark
           v-on:click="requestActivationLink">Request activation link</v-btn>

    <p v-if="showEmailSuccess">
      Please check your email for an activation link and follow the instructions.
    </p>
    <p v-if="showEmailFailure">
      Activation email could not be sent. Please contact admin@omniuserportal.com for support.
    </p>
  </v-container>
</template>
<script>
  import FormField from "../objs/Shared/FormField.ts";
  import { FieldTypes } from "../objs/Shared/FieldTypes.js";


  export default {
    beforeRouteEnter (to, from, next) {
  next(vm => {
    var token = vm.$route.query.token;
    if (token == null)
    {
      console.log("The token is null");
      vm.$router.push("/");
    }
  })
},
    data: function ()
    {
      return {
        showSuccess: false,
        showFailure: false,
        showMessageSuccess: false,
        showMessageFailure: false
      };
    },
    computed: {
      fromCallback: function ()
      {
        var token = this.$route.query.token;
        return token == null;
      }

    },
    methods: {

      submit: async function ()
      {
        var token = this.$route.query.token;
        var userId = this.$route.query.userId;

        var succeeded = await this.$store.dispatch("securityModule/confirmAccount", {
          userId: userId,
          token: token,
        });
        if (succeeded)
        {
          this.showSuccess = true;
        } else
        {
          this.showFailure = true;
        }
      }
      ,
        
      requestActivationLink: async function ()
      {
        var userId = this.$route.query.userId;

        var succeeded = await this.$store.dispatch("securityModule/requestActivationLink", {
          userId: userId
        });
        if (succeeded)
        {
          this.showFailure = false;
          this.showEmailSuccess = true;
        } else
        {
                    this.showFailure = false;
          this.showEmailFailure = true;
        }
      }
    }

   
  };
</script>
