<template>
  <v-container
    class="mt-12 n-sub-container"
    style="border:2px solid;float:center;"
  >
    <v-card flat class="mar-6">
      <span class="text-h5 ml-4">Profile</span>
      <v-list>
        <!-- <v-list-item class="mt-6"> -->
        <div class="text-center">
          <span class="text-h5">{{ fullName }}</span>
          <v-list-item-subtitle v-text="position"></v-list-item-subtitle>
        </div>
        <!-- </v-list-item> -->
        <div class="text-center">
          <user-profile-actions></user-profile-actions>
        </div>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>User Name</v-list-item-title>
            <v-list-item-subtitle v-text="userName"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Personal Email</v-list-item-title>
            <v-list-item-subtitle v-text="personalEmail"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Phone Number</v-list-item-title
            ><v-list-item-subtitle v-text="phoneNumber"></v-list-item-subtitle
          ></v-list-item-content>
        </v-list-item>
        <!-- </v-list> -->
        <!-- <v-list v-if="!messagingUserEmpty"> -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Messaging Email Address</v-list-item-title>
            <v-list-item-subtitle
              v-text="messagingAddress"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Display Name</v-list-item-title>
            <v-list-item-subtitle v-text="displayName"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Notification Preferences</v-list-item-title>
            <v-list-item-subtitle v-text="preferences"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="hasEmailPreference">
          <v-list-item-content>
            <v-list-item-title>Secondary Alert Email Address</v-list-item-title>
            <v-list-item-subtitle
              v-text="secondaryAlertEmailAddress"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>
<script>
import UserProfileActions from "./user-profile-actions";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    "user-profile-actions": UserProfileActions
  },
  computed: {
    ...mapState("securityModule", {
      user: state => state.dashboardUser
    }),
    fullName: function() {
      return this.user.firstName + " " + this.user.lastName;
    },
    position: function() {
      return this.user.title;
    },
    personalEmail: function() {
      return this.user.email;
    },
    phoneNumber: function() {
      return this.user.phoneNumber;
    },
    messagingUserEmpty: function() {
      return this.user.messagingUserId == null;
    },
    preferences: function() {
      return this.user.preferences;
    },
    hasEmailPreference: function() {
      return this.preferences == "Email alert" || this.preferences == "Both";
    },
    userName: function() {
      return this.user.userName;
    },
    displayName: function() {
      return this.user.displayName;
    },
    messagingAddress: function() {
      return this.user.messagingAddress;
    },
    secondaryAlertEmailAddress: function() {
      return this.user.secondaryAlertEmailAddress;
    }
  },
  methods: {
    ...mapActions("securityModule", ["resetPassword"]),
    passwordReset: function() {
      return this.resetPassword();
    }
  }
};
</script>
