<template>
  <v-dialog v-model="dialog" persistent width="850">
    <template v-slot:activator="{ on }">
      <v-btn class="mr-2" v-on="on" dark icon color="blue-grey lighten-1" v-on:click="loadEditableUser">
        <icon icon="pen"></icon
      ></v-btn>
    </template>

    <v-card width="950">
      <v-card-title class="headline">Edit profile</v-card-title>
      <v-row dense justify="center">
        <v-col cols="10" class="mx-auto">
          <form-field
            :field="firstNameField"
            :validations="$v.user.firstName"
            outlined="true"
          ></form-field>
          <form-field
            :field="lastNameField"
            :validations="$v.user.lastName"
          ></form-field>
          <form-field
            :field="emailField"
            :validations="$v.user.email"
          ></form-field>
          <form-field
            :field="phoneNumberField"
            :validations="$v.user.phoneNumber"
          ></form-field>
          <form-field
            :field="displayNameField"
            :validations="$v.user.displayName"
          ></form-field>
          <form-field
            :field="notificationField"
            :validations="$v.user.preferences"
          ></form-field>
          <form-field
            v-if="hasEmailPreference"
            :field="secondaryAlertEmailAddressField"
            :validations="$v.user.secondaryAlertEmailAddress"
          ></form-field></v-col
      ></v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="blue-grey" dark @click="toggleDialog">
          Cancel</v-btn
        ><v-btn color="blue-grey" dark @click="update">Save Changes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// userName = "", //TODO doesn't change
// firstName = "",
// lastName = "",
// title = "",
// email = "",
// phoneNumber = "",
// messagingAddress = "", //TODO doesn't change
// displayName = "",
// preferences = "",
// secondaryAlertEmailAddress = ""
const alphaExtra = value => {
  if (value === "") {
    return true;
  }
  return /^[-.a-zA-Z\s]+$/g.test(value);
};
import { FieldTypes } from "../objs/Shared/FieldTypes";
import Field from "../objs/Shared/FormField";
import { email, required, minLength, alpha } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapState("securityModule", {
      user: state => state.backupUser
    }),
    messagingUserEmpty: function() {
      return this.user.messagingUserId == null;
    },

    hasEmailPreference: function() {
      return this.user.preferences == "Email alert" ||  this.user.preferences == "Both";
    },
    firstNameField: function() {
      return new Field({
        tier: 1,
        label: "First Name",
        componentName: FieldTypes.TEXT_FIELD,
        moduleName: "securityModule",
        objName: "backupUser",
        propName: "firstName",
        outlined: true
      });
    },
    lastNameField: function() {
      return new Field({
        tier: 1,
        label: "Last Name",
        componentName: FieldTypes.TEXT_FIELD,
        moduleName: "securityModule",
        objName: "backupUser",
        propName: "lastName",
        outlined: true
      });
    },
    titleField: function() {
      return new Field({
        tier: 1,
        label: "Title",
        componentName: FieldTypes.TEXT_FIELD,
        moduleName: "securityModule",
        objName: "backupUser",
        propName: "title",
        outlined: true
      });
    },
    emailField: function() {
      return new Field({
        tier: 1,
        label: "Email",
        componentName: FieldTypes.TEXT_FIELD,
        moduleName: "securityModule",
        objName: "backupUser",
        propName: "email",
        outlined: true
      });
    },
    phoneNumberField: function() {
      return new Field({
        tier: 1,
        label: "Phone Number",
        componentName: FieldTypes.PHONE_NUMBER_FIELD,
        moduleName: "securityModule",
        objName: "backupUser",
        propName: "phoneNumber",
        outlined: true
      });
    },
    displayNameField: function() {
      return new Field({
        tier: 1,
        label: "Display Name",
        componentName: FieldTypes.TEXT_FIELD,
        moduleName: "securityModule",
        objName: "backupUser",
        propName: "displayName",
        outlined: true
      });
    },
    notificationField: function() {
      return new Field({
        tier: 1,
        label: "Notification Preferences",
        componentName: FieldTypes.RADIO_GROUP,
        moduleName: "securityModule",
        objName: "backupUser",
        propName: "preferences",
        options: ["Email alert", "Text message alert", "Both"]
      });
    },
    secondaryAlertEmailAddressField: function() {
      return new Field({
        tier: 1,
        label: "Secondary Email Address",
        componentName: FieldTypes.TEXT_FIELD,
        moduleName: "securityModule",
        objName: "backupUser",
        propName: "secondaryAlertEmailAddress",
        outlined: true
      });
    }
  },
  validations() {
    return {
      user: {
        firstName: { alpha, required },
        lastName: { alpha, required },
        title: { alphaExtra, required },
        email: { email, required },
        phoneNumber: { required, minLength: minLength(10) },
        displayName: { alphaExtra, required },
        preferences: { required },
        secondaryAlertEmailAddress: { email }
      }
    };
  },
  methods: {
    ...mapActions("securityModule", ["updateUserProfile"]),
    update: function() {
      this.toggleDialog();
      this.updateUserProfile();
    },
    toggleDialog: function() {
      if (this.dialog == false) {
        this.dialog = true;
        return;
      }
      if (this.dialog == true) {
        this.dialog = false;
        return;
      }
    },
    loadEditableUser: function ()
    {
      this.$store.commit('securityModule/backupUser');
    }
  }
};
</script>
