<template>
  <v-container class="mt-12" row fluid>
              <tile-item :item="item" v-for="(item, index) in userApplications"
              :key="index" class="mr-10"></tile-item> 
  </v-container>
</template>

<script>
import TileItem from "./tile-item";
import { mapState } from "vuex";
  export default {
    mounted: async function ()
    {
      var apps = this.$store.state.securityModule.userApplications;
      //if (apps.length < 1)
      //{
      //        this.$store.dispatch('securityModule/getClientsForUser');

      //}
    },
  
  components: {
    "tile-item": TileItem
  },
  //data() {
  //  return {
  //    userApplications: [
  //      {
  //        icon: 'user',
  //        name: 'Messagine',
  //        alerts: 0,
  //      },
  //      {
  //        icon: 'user',
  //        name: 'Messagine',
  //        alerts: 1,
  //      },
  //    ]
  //  }
  //}
   computed: {
     ...mapState("securityModule", ["userApplications"])
   }
};
</script>
