<template>
  <div>
    

    <section>
      <v-layout column
                wrap
                class="my-12"
                align-center>
        <v-flex xs12 sm4 class="my-4">
          <div class="text-center">
            <h2 class="headline">WELCOME TO OMNI USER PORTAL</h2>
            <p class="subheading" style="color:#525252; font-style:italic">
              <br/>Access custom built, feature packed applications for providers and <br /> administrators at Omni's innovative user portal.
            </p>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon style="color:#1c344b" x-large class="dark-puple">mdi-account-circle</v-icon>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">Profile</div>
                  </v-card-title>
                  <v-card-text>
                    Manage your personal information and Omni account credentials. Easily update your preferences and details on the Profile page.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large style="color:#1c344b">mdi-monitor-screenshot</v-icon>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Apps</div>
                  </v-card-title>
                  <v-card-text>
                    Access all your Omni apps. Our apps help automate and streamline complex provider and administrative tasks, and manage secure communications.
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large style="color:#1c344b">mdi-chat-processing</v-icon>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Support</div>
                  </v-card-title>
                  <v-card-text>
                    Technical support is only a click away. Submit issues, feedback, and feature requests right here on the portal. We look forward to working with you!
                  </v-card-text>
                </v-card>
              </v-flex>

            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>

    <section>
        <v-layout column align-center justify-center>
          <v-row>
            <v-col cols="6">
              <v-btn class="mt-2 mb-12"
                     color="#1c344b"
                     dark
                     large
                     v-on:click="login">
                Login to get started
              </v-btn>
            </v-col>

           
          </v-row>
          <br />
          <br />
        </v-layout>
    </section>

    <section>
    </section>

    <v-footer color="#1c344b" fixed>
      <v-layout row wrap align-center>
        <v-flex xs12>
          <div class="white--text ml-4" style="font-size:10px">
            Developed by OmniRehabTech
          </div>
        </v-flex>
      </v-layout>
    </v-footer>
  </div>
</template>



<script>
  export default {
    data: function ()
    {
      return {
        title: "Omni User Portal"
      }
    },
    mounted: function ()
    {
    },
    methods: {
      login: function ()
      {
        var currentPath = this.$router.currentRoute.path;
        this.$store.dispatch('securityModule/authenticate', currentPath);
      }
     
    }
  }
</script>

<style>
  .dark-puple {
    color: #542344;
  }
</style>





