<template>
  <div>
    <transition appear name="slide-fade" mode="out-in">
      <tile-grid></tile-grid>
    </transition>
  </div>
</template>
<script>
import TileGrid from "./tile-grid";

export default {
  created() {
    //this.$store.dispatch("dashboardModule/getDashboardProfile");
  },
  components: {
    "tile-grid": TileGrid
  }
};
</script>
