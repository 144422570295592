import Vue from "vue";
import Vuex from "vuex";

import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

Vue.config.devtools = true;

import uxModule from "./Modules/uxModule";
import formFieldsModule from "./Modules/formFieldsModule";
import securityModule from "./Modules/securityModule";
import sharedModule from "./Modules/sharedModule";
const state = {};

const mutations = {};

const actions = {};
const vuexLocal = new VuexPersistence({
  supportCircular: true,
  storage: window.localStorage
});

Vue.config.devtools = true;

export default new Vuex.Store({
  modules: {
    uxModule: uxModule,
    formFieldsModule: formFieldsModule,
    securityModule: securityModule,
    sharedModule: sharedModule
  },

  state,
  mutations,
  actions
  //plugins: [vuexLocal.plugin]
});
