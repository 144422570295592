<template>
  <div>
    <component v-bind:is="componentName"></component>      
  </div>
</template>

<script>
  import Welcome from './welcome'
  import SiteGridContainer from "./ApplicationTileGrid/grid-container";


  export default {
    components: {
      'welcome': Welcome,
      'apps': SiteGridContainer
    },
    computed: {
      componentName: function ()
      {
        if (this.$store.state.securityModule.isAuthenticated)
        {
          return 'apps'
        } else
        {
          return 'welcome';
        }
      }
    }

  }
</script>
